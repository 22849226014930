<template>
  <div class="va-row">

    <div class="flex md1"></div>

    <div class="flex md10">
      <vuestic-widget>
        <vuestic-timeline-item active>
          <template slot="before">
            <div class="vuestic-timeline-item__title">
              <h2>Para que serve um relato?</h2>
            </div>
          </template>
        </vuestic-timeline-item><br>
        <p class="text-center">Esta área está destinada a enviar
          denúncias anônimas sobre condições de segurança da empresa.</p>

        <p class="text-center">Escolha uma empresa cadastrada para relatar a ocorrência.</p> <br>
        <div class="va-row">
          <div class="flex md4"></div>
          <div class="flex md4 xs12">
            <form autocomplete="off">
              <fieldset>
                <div class="form-group">
                  <div class="input-group">
                    <label class="select-control-label">Empresa</label>
                    <multiselect
                      v-model="empresaSelecionada"
                      placeholder="Selecione"
                      track-by="id"
                      label="nome"
                      :options="empresas"
                      selectedLabel="Selecionado"
                      select-label="Selecione uma Empresa"
                      deselect-label="Remover uma Empresa"
                    >
                      <template slot="noOptions">Sem Empresas</template>
                      <span slot="noResult">Empresa não encontrada.</span>
                    </multiselect>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>

        <div class="va-row">
          <div class="flex md4">
          </div>
          <div class="flex md2">
            <button
              class="btn btn-dark btn-micro"
              @click="$router.push({name: 'login'})"
            >Sair</button>
          </div>
          <div class="flex md3">
            <button
              class="btn btn-success btn-micro"
              @click="goRelato"
            >Continuar</button>
          </div>

        </div>
      </vuestic-widget>
    </div>
  </div>
</template>

<script>


export default {
  name: 'relatos',
  data() {
    return {
      empresas: [],
      empresaSelecionada: null,
    };
  },
  created() {
    this.getEmpreasa();
  },
  methods: {
    getEmpreasa() {
      this.$axios.get('/api/empresa-list/').then((res) => {
        this.empresas = res.data;
      });
    },
    goRelato() {
      if (this.empresaSelecionada) {
        this.$router.push({
          name: 'relatar',
          params: { empresa: this.empresaSelecionada.id, nome: this.empresaSelecionada.nome },
        });
      } else {
        this.$swal({
          toast: true,
          position: 'top-end',
          showCancelButton: false,
          type: 'info',
          title: 'Escolha uma empresa',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
$break-clr: 520px;

.flex {
  align-content: center;
}

.center {
  align-items: center;
}

.vuestic-widget {
  margin-top: 3rem;
}
h2,
h2 {
  margin-top: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
